import React from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';
import MembershipAgreementModal from '../components/MembershipAgreementModal';

import clientData from '../inc/clientData';
import config from '../inc/config';

import Footer from '../components/Footer';
import Images from '../utils/Images';
import IlluminationAggreementModal from '../components/IlluminationAggreementModal';

class RegisterModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isOpenMembershipAgreement: false,
      isOpenKVKK: false,

      firstname: '',
      lastname: '',
      username: '',
      email: '',
      password: '',
      passwordRepeat: '',
      acceptKVKK: false,
      acceptTerms: false
    };

    this.formOnSubmit = this.formOnSubmit.bind(this);
  }

  componentDidMount() {
    console.log('Register: componentDidMount()');
  }

  componentWillUnmount() {
    console.log('Register: componentWillUnmount()');
  }

  formOnSubmit(event) {
    event.preventDefault();

    let infoMessage = '';

    if (!this.state.firstname) {
      infoMessage = 'Adı boş bırakılamaz.';
    } else if (!this.state.lastname) {
      infoMessage = 'Soyadı boş bırakılamaz.';
    } else if (!this.state.username) {
      infoMessage = 'Kullanıcı Adı boş bırakılamaz.';
    } else if (!this.state.email) {
      infoMessage = 'E-Posta adresi boş bırakılamaz.';
    } else if (!this.state.password) {
      infoMessage = 'Şifre boş bırakılamaz.';
    } else if (!this.state.passwordRepeat) {
      infoMessage = 'Şifre tekrarı boş bırakılamaz.';
    } else if (this.state.password !== this.state.passwordRepeat) {
      infoMessage = 'Şifre ile şifre tekrarı eşleşmiyor.';
    } else if (!this.state.acceptKVKK) {
      infoMessage = 'Aydınlatma metnini kabul etmelisiniz.';
    } else if (!this.state.acceptTerms) {
      infoMessage = 'Kullanım sözleşmesini kabul etmelisiniz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({
          firstname: this.state.firstname,
          lastname: this.state.lastname,
          username: this.state.username,
          email: this.state.email,
          password: this.state.password
        })
      };

      fetch(config.restApi + 'register', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        switch (responseData.status) {
          case 200:
            window.location.reload();
          break;
          case 400:
            swal({dangerMode: true, icon: 'error', title: 'Hata', text: responseData.message, buttons: 'Yeniden Yükle'}).then(() => window.location.reload());
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            }).then(() => this.setState({isLoading: false}));
          break;
        }
      }, () => this.setState({isLoading: false}, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'})));
    }
  }

  render() {
    return  this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="wrapper wrapper-content pb-4">
          <div className="text-center">
            <h2 className="font-normal text-dark">Kullanıcı Kayıt</h2>
            <h4 className="font-normal">Kullanıcı kaydı yapabilmek için aşağıdaki bilgileri doldurunuz.</h4>
          </div>
          <div className="middle-box p-5 text-center animated fadeInDown border bg-white mt-2">
            <div className="mb-5"><Link alt="logo" to={'//www.' + config.host}><img alt="Logo" src={Images.logo} /></Link></div>
            <form className="mt-2" onSubmit={this.formOnSubmit}>
              <div className="row">
                <div className="col-6">
                  <h5 className="text-left font-normal text-dark">Adınız</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({firstname: event.target.value})} type="text" value={this.state.firstname} />
                </div>
                <div className="col-6">
                  <h5 className="text-left font-normal text-dark">Soyadınız</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({lastname: event.target.value})} type="text" value={this.state.lastname} />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col-6">
                  <h5 className="text-left font-normal text-dark">Kullanıcı Adınız</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({username: event.target.value})} type="text" value={this.state.username} />
                </div>
                <div className="col-6">
                  <h5 className="text-left font-normal text-dark">E-posta</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({email: event.target.value})} type="email" value={this.state.email} />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col">
                  <h5 className="text-left font-normal text-dark">Şifre</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({password: event.target.value})} type="password" value={this.state.password} />
                </div>
              </div>
              <div className="row mt-1">
                <div className="col">
                  <h5 className="text-left font-normal text-dark">Şifre Tekrar</h5>
                  <input className="form-control form-input mr-2" onChange={event => this.setState({passwordRepeat: event.target.value})} type="password" value={this.state.passwordRepeat} />
                </div>
              </div>
              <div className="text-center mt-2">
                <label>
                  <input checked={this.state.acceptKVKK} className="form-check-input" onChange={event => this.setState({acceptKVKK: event.target.checked})} type="checkbox" />
                  <small>Kişisel verilerimin işlenmesine yönelik<span className="text-blue pointer" onClick={() => this.setState({isOpenKVKK: true})}> Aydınlatma Metni'ni</span> okudum ve analdım.</small>
                </label>
                <MembershipAgreementModal isOpen={this.state.isOpenKVKK} modalTitle="Aydınlatma Metni" onRequestClose={() => this.setState({isOpenKVKK: false})} />
                <label>
                  <input checked={this.state.acceptTerms} className="form-check-input" onChange={event => this.setState({acceptTerms: event.target.checked})} type="checkbox" />
                  <small><span className="text-blue pointer" onClick={() => this.setState({isOpenMembershipAgreement: true})}>Üyelik Sözleşmesini</span> okudum ve onayladım.</small>
                </label>
                <IlluminationAggreementModal isOpen={this.state.isOpenMembershipAgreement} modalTitle="Üyelik Sözleşmesi" onRequestClose={() => this.setState({isOpenMembershipAgreement: false})} />
              </div>
              <button className="btn btn-login bg-orange text-white block full-width mt-4 mb-2">Hesap Oluştur</button>
              <div className="d-flex justify-content-center">
                <p className="text-dark mr-1">Hesabın var mı?</p>
                <Link className="text-darkblue" to={"/login" + (this.props.searchParams.get('ref') ? '?ref=' + this.props.searchParams.get('ref') : '')}>Giriş Yap</Link>
              </div>
            </form>
          </div>
        </div>
        <Footer/>
      </>
  }
}

export default function Register() {
  return (
    <RegisterModule searchParams={useSearchParams()[0]} />
  )
}