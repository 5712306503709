import React from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';
import {setCookie} from '../inc/cookies';

class DirectLoginModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sessionHash: this.props.searchParams.get('sessionHash') || ''
    };
  }

  componentDidMount() {
    console.log('DirectLogin: componentDidMount()');
    this.doJob();
  }

  componentWillUnmount() {
    console.log('DirectLogin: componentWillUnmount()');
  }

  doJob() {
    let infoMessage = '';

    if (!this.state.sessionHash) {
      infoMessage = 'Doğrulama kodu boş bırakılamaz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      }).then(() => this.props.navigate('/'));
    } else {
      clientData.sessionHash = this.state.sessionHash;
      clientData.sessionType = 'member';

      setCookie('sessionHash', clientData.sessionHash, 31536000);
      setCookie('sessionType', clientData.sessionType, 31536000);

      swal({
        dangerMode: false,
        icon: 'success',
        title: 'Tamamlandı',
        text: 'Giriş işlemi başarıyla tamamlandı.',
        buttons: 'Tamam'
      }).then(() => window.location.replace('//banaozel.' + config.host));
    }
  }

  render() {
    return (
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
    )
  }
}

export default function DirectLogin() {
  return (
    <DirectLoginModule navigate={useNavigate()} searchParams={useSearchParams()[0]} />
  )
}