import customerServiceIcon from '../assets/img/customerServiceIcon.png';
import etbisIcon from '../assets/img/etbisIcon.png';
import helpCenterIcon from '../assets/img/helpCenterIcon.png';
import logo from '../assets/img/logo.png';
import trGoIcon from '../assets/img/trGoIcon.png';

const Images = {
  customerServiceIcon,
  etbisIcon,
  helpCenterIcon,
  logo,
  trGoIcon
}

export default Images;