import React, { Component } from 'react'
import Modal from 'react-modal';
import config from '../inc/config';

export default class MembershipAgreementModal extends Component {

  constructor(props) {
    super(props);

    this.state={
      isCompleted: true
    }

  }

  render() {
    return (
      <>
        <div className="modal inmodal">
          <div className="modal-dialog">
            <div className="modal-content animated fadeIn">
            <Modal
              isOpen={this.props.isOpen}
              onRequestClose={this.props.onRequestClose}
              style={{
                content : {
                  position : 'null',
                  top : 'null',
                  left : 'null',
                  right : 'null',
                  bottom : 'null',
                  border : 'none',
                  background : '#fff',
                  padding : 'null',
                  maxWidth : '560px',
                  minWidth : '400px',
                },
                overlay : {
                  position : 'fixed',
                  top : 0,
                  left : 0,
                  right : 0,
                  bottom : 0,
                  display : 'flex',
                  alignItems : 'center',
                  justifyContent : 'center',
                  backgroundColor : 'rgba(0,0,0,00.1)'
                }
              }}
            >
              <div className={this.state.isCompleted ? "ibox-content no-padding" : "ibox-content sk-loading no-padding"}>
                <div className="sk-spinner sk-spinner-fading-circle">
                  <div className="sk-circle1 sk-circle"></div>
                  <div className="sk-circle2 sk-circle"></div>
                  <div className="sk-circle3 sk-circle"></div>
                  <div className="sk-circle4 sk-circle"></div>
                  <div className="sk-circle5 sk-circle"></div>
                  <div className="sk-circle6 sk-circle"></div>
                  <div className="sk-circle7 sk-circle"></div>
                  <div className="sk-circle8 sk-circle"></div>
                  <div className="sk-circle9 sk-circle"></div>
                  <div className="sk-circle10 sk-circle"></div>
                  <div className="sk-circle11 sk-circle"></div>
                  <div className="sk-circle12 sk-circle"></div>
                </div>
                <div className="modal-header d-block text-center text-darkblue">
                  <button className="close" onClick={this.props.onRequestClose}><span>×</span></button>
                  <h4 className="modal-title">{this.props.modalTitle}</h4>
                </div>
                <div className="modal-body text-darkblue">
                  <iframe className="border-0" src={`https://www.${config.host}/gizlilik-sozlesmesi`} title="Gizlilik Sözleşmesi" width="500" height="300"></iframe>
                </div>
                <div className="modal-footer">
                  <button className="btn btn-orange" onClick={this.props.onRequestClose}>Tamam</button>
                </div>
              </div>
            </Modal>
            </div>
          </div>
        </div>
      </>
    )
  }
}
