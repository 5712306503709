import React from 'react';
import {Link, useSearchParams} from 'react-router-dom';

import swal from 'sweetalert';

import clientData from '../inc/clientData';
import config from '../inc/config';

import Footer from '../components/Footer';
import Images from '../utils/Images';

class ForgetPasswordModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: ''
    };

    this.formOnSubmit = this.formOnSubmit.bind(this);
  }

  componentDidMount() {
    console.log('ForgetPassword: componentDidMount()');
  }

  componentWillUnmount() {
    console.log('ForgetPassword: componentWillUnmount()');
  }

  formOnSubmit(event) {
    event.preventDefault();

    let infoMessage = '';

    if (!this.state.email) {
      infoMessage = 'E-Posta adresi boş bırakılamaz.';
    }

    if (infoMessage) {
      swal({
        dangerMode: true,
        icon: 'info',
        title: 'Eksik Bilgi',
        text: infoMessage,
        buttons: 'Tamam'
      });
    } else {
      this.setState({isLoading: true});

      const requestOptions = {
        method: 'POST',
        headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
        body: JSON.stringify({
          email: this.state.email,
          ref: this.props.searchParams.get('ref') || ''
        })
      };

      fetch(config.restApi + 'forgetPassword', requestOptions)
      .then(response => response.json())
      .then(responseData => {
        this.setState({isLoading: false});

        switch (responseData.status) {
          case 200:
            swal({
              dangerMode: false,
              icon: 'success',
              title: 'Tamamlandı',
              text: 'Sıfırlama bilgileri E-Posta adresinize gönderildi.',
              buttons: 'Tamam'
            });
          break;
          case 400:
            swal({dangerMode: true, icon: 'error', title: 'Hata', text: responseData.message, buttons: 'Yeniden Yükle'}).then(() => window.location.reload());
          break;
          default:
            swal({
              dangerMode: true,
              icon: 'warning',
              title: 'Hay Aksi',
              text: responseData.message,
              buttons: 'Tamam'
            });
          break;
        }
      }, () => this.setState({isLoading: false}, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Tamam'})));
    }
  }

  render() {
    return  this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <>
        <div className="wrapper wrapper-content">
          <div className="text-center">
            <h2 className="font-normal text-dark">Şifre Yenileme</h2>
            <h4 className="font-normal">Şifre yenileme bağlantısını gönderebilmemiz için e-posta adresinize ihtiyacımız var.</h4>
          </div>
          <div className="middle-box p-5 text-center animated fadeInDown border bg-white mt-2">
            <div className="mb-5"><Link alt="logo" to={'//www.' + config.host}><img alt="Logo" src={Images.logo} /></Link></div>
            <form className="mt-2" onSubmit={this.formOnSubmit}>
              <div className="form-group">
                <h4 className="text-left">E-Posta</h4>
                <input className="form-control" onChange={event => this.setState({email: event.target.value})} type="email" value={this.state.email} />
              </div>
              <div className="mt-5">
                <button className="btn btn-login full-width bg-orange text-white mb-2">Şifremi Yenile</button>
                <Link className="btn full-width fp-button" to={"/login" + (this.props.searchParams.get('ref') ? '?ref=' + this.props.searchParams.get('ref') : '')}>Giriş Yap</Link>
              </div>
            </form>
          </div>
        </div>
        <Footer/>
      </>
  }
}

export default function ForgetPassword() {
  return (
    <ForgetPasswordModule searchParams={useSearchParams()[0]} />
  )
}