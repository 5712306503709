import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';

import swal from 'sweetalert';
import Modal from 'react-modal';

import clientData from './inc/clientData';
import config from './inc/config';
import {randomUUID} from './inc/compat';
import {getCookie, setCookie} from './inc/cookies';

import DirectLogin from './pages/DirectLogin';
import EmailActivation from './pages/EmailActivation';
import ForgetPassword from './pages/ForgetPassword';
import Login from './pages/Login';
import MagicLink from './pages/MagicLink';
import MagicLogin from './pages/MagicLogin';
import Register from './pages/Register';
import ResetPassword from './pages/ResetPassword';

import '../src/assets/css/index.css';

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true
    };
  }

  componentDidMount() {
    console.log('Index: componentDidMount()');
    this.init();
  }

  componentWillUnmount() {
    console.log('Index: componentWillUnmount()');
  }

  init() {
    clientData.deviceId = getCookie('deviceId', randomUUID());
    clientData.sessionHash = getCookie('sessionHash', '');

    const requestOptions = {
      method: 'POST',
      headers: {'Authorization': 'Bearer ' + clientData.sessionHash, 'Content-Type': 'application/json'},
      body: JSON.stringify({
        deviceId: clientData.deviceId,
        deviceManufacturer: '',
        deviceModel: '',
        osLanguage: '',
        osVersion: '',
        versionCode: '',
        versionName: '',
        platform: 'web',
        token: '',
        timezone: '+03:00',
        userAgent: navigator.userAgent
      })
    };

    fetch(config.restApi + 'init', requestOptions)
    .then(response => response.json())
    .then(responseData => {
      switch (responseData.status) {
        case 200:
          clientData.sessionHash = responseData.sessionHash;
          clientData.sessionType = responseData.sessionType;

          setCookie('deviceId', clientData.deviceId, 31536000);
          setCookie('sessionType', clientData.sessionType, 31536000);

          clientData.sessionType === 'anonymous' && setCookie('sessionHash', clientData.sessionHash, 31536000);

          this.start(responseData);
        break;
        default:
          swal({dangerMode: true, icon: 'error', title: 'Hay Aksi', text: responseData.message, buttons: 'Yeniden Dene'}).then(() => this.init());
        break;
      }
    }, () => swal({dangerMode: true, icon: 'error', title: 'Bağlantı Hatası', text: 'Lütfen internet bağlantınızı kontrol ediniz.', buttons: 'Yeniden Yükle'}).then(() => this.init()));
  }

  start(responseData) {
    if (responseData.sessionType === 'anonymous' || window.location.pathname === '/emailActivation' || window.location.pathname === '/resetPassword') {
      this.setState({isLoading: false});
    } else {
      let ref = new URLSearchParams(window.location.search).get('ref');
      switch(ref) {
        case 'banaozel':
          window.location.replace('//banaozel.' + config.host);
        break;
        case 'cekici':
          window.location.replace('//cekici.' + config.host);
        break;
        case 'ekspertiz':
          window.location.replace('//ekspertiz.' + config.host);
        break;
        case 'kredi':
          window.location.replace('//kredi.' + config.host);
        break;
        case 'sigorta':
          window.location.replace('//sigorta.' + config.host);
        break;
        case 'web':
          window.location.replace('//www.' + config.host);
        break;
        default:
          window.location.replace(ref || '//www.' + config.host);
        break;
      }
    }
  }

  render() {
    return this.state.isLoading ?
      <div className="ibox-content sk-loading gray-bg w-100 h-100">
        <div className="sk-spinner sk-spinner-fading-circle">
          <div className="sk-circle1 sk-circle"></div>
          <div className="sk-circle2 sk-circle"></div>
          <div className="sk-circle3 sk-circle"></div>
          <div className="sk-circle4 sk-circle"></div>
          <div className="sk-circle5 sk-circle"></div>
          <div className="sk-circle6 sk-circle"></div>
          <div className="sk-circle7 sk-circle"></div>
          <div className="sk-circle8 sk-circle"></div>
          <div className="sk-circle9 sk-circle"></div>
          <div className="sk-circle10 sk-circle"></div>
          <div className="sk-circle11 sk-circle"></div>
          <div className="sk-circle12 sk-circle"></div>
        </div>
      </div>
      :
      <BrowserRouter>
        <Routes>
          <Route path="/directLogin" element={<DirectLogin />} />
          <Route path="/emailActivation" element={<EmailActivation />} />
          <Route path="/forgetPassword" element={<ForgetPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="/magicLink" element={<MagicLink />} />
          <Route path="/magicLogin" element={<MagicLogin />} />
          <Route path="/register" element={<Register />} />
          <Route path="/resetPassword" element={<ResetPassword />} />
          <Route path="/*" element={<Navigate to="/login" />} />
        </Routes>
      </BrowserRouter>
  }
}

const appElement = document.getElementById('root');
Modal.setAppElement(appElement);
ReactDOM.createRoot(appElement).render(<Index />);